import React, { useRef, useState } from 'react'
import { Alert, Avatar, Badge, Box, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Input, Link, List, ListItem, ListItemButton, Paper, TextField, Typography  } from '@mui/material'
import { GetUserInfoQuery, TeamType, useGetUserInfoQuery, useResetTeamMutation, useUpdateUserEmailMutation, useUpdateUserNameMutation } from '../../graphql/generated'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { Construction, Delete, DeleteForever, Edit, Email, Error, Foundation, Handshake, Hardware, House, Info, OpenInBrowser, Refresh, SupervisorAccount, Sync, Warning } from '@mui/icons-material'
import { deepPurple } from '@mui/material/colors'
import { getInitials } from '../../common/utils/text'
import { useRequiredContext } from '../../common/hooks/useRequiredContext'
import { EnvContext } from '../../Bootstrapper'
import { useSearchState } from '../../common/hooks/pages'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

type ResetTeamState = {
  type: "resetTeam",
  userInfo: NonNullable<GetUserInfoQuery["getUserInfo"]>,
  team: NonNullable<GetUserInfoQuery["getUserInfo"]>["teams"][number],
}
type UpdateUserEmailState = {
  type: "updateUserEmail",
  userInfo: NonNullable<GetUserInfoQuery["getUserInfo"]>,
}

type UpdateUserNameState = {
  type: "updateUserName",
  userInfo: NonNullable<GetUserInfoQuery["getUserInfo"]>,
}

type ModalState = ResetTeamState | UpdateUserEmailState | UpdateUserNameState | undefined

const zSearchForm = z.object({
  search: z.string().optional(),
})
type SearchForm = z.infer<typeof zSearchForm>

const zUpdateNameForm = z.object({
  givenName: z.string().optional(),
  familyName: z.string().optional(),
})
type UpdateNameForm = z.infer<typeof zUpdateNameForm>

const zUpdateEmailForm = z.object({
  email: z.string().email(),
  confirmEmail: z.string().email(),
}).superRefine((data, ctx) => {
  if (data.email !== data.confirmEmail) {
    ctx.addIssue({
      path: [ "confirmEmail" ],
      code: "custom",
      message: "Emails must be identical to proceed.",
    })
  }
})
type UpdateEmailForm = z.infer<typeof zUpdateEmailForm>

const UpdateNameModalContent: React.FC<{state: UpdateUserNameState, onClose: (success: boolean) => Promise<void>}> = ({ state, onClose }) => {
  const gqlDataSrc = useCoreApiSource()
  const updateUserName = useUpdateUserNameMutation(gqlDataSrc)

  const updateNameForm = useForm<UpdateNameForm>({
    resolver: zodResolver(zUpdateNameForm),
  })

  const submitUpdateNameForm = updateNameForm.handleSubmit(async (data) => {
    await updateUserName.mutateAsync({
      email: state.userInfo.email,
      familyName: data.familyName,
      givenName: data.givenName,
    })
    await onClose(true)
  })

  return (state?.type === "updateUserName" ? <>
    <DialogTitle>Update User: {state.userInfo.individual.givenName} {state.userInfo.individual.familyName}</DialogTitle>
    <DialogContent>
      <DialogContentText>Update this individual&apos;s name in the app and in auth0.</DialogContentText>
      <Box sx={{ gap: 2 , display: "flex", mt:2 }}>
        <TextField
          id="name"
          label="Given Name"
          error={!!updateNameForm.formState.errors.givenName}
          helperText={updateNameForm.formState.errors.givenName?.message}
          {...updateNameForm.register("givenName")}
        />
        <TextField
          id="name"
          label="Family Name"
          error={!!updateNameForm.formState.errors.familyName}
          helperText={updateNameForm.formState.errors.familyName?.message}
          {...updateNameForm.register("familyName")}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>Cancel</Button>
      <Button variant='contained' onClick={submitUpdateNameForm} disabled={updateNameForm.formState.isSubmitting} startIcon={updateNameForm.formState.isSubmitting && <CircularProgress size="1rem" sx={{ color:"white" }} />}>Save</Button>
    </DialogActions>
    {updateUserName.isError ? <Typography color="error.main">{String(updateUserName.error)}</Typography> : null}
  </>: null
  )
}

const UpdateUserEmailContent: React.FC<{state: UpdateUserEmailState, onClose: (success: boolean) => Promise<void>}> = ({ state, onClose }) => {
  const gqlDataSrc = useCoreApiSource()
  const updateUserEmail = useUpdateUserEmailMutation(gqlDataSrc)

  const updateEmailForm = useForm<UpdateEmailForm>({
    resolver: zodResolver(zUpdateEmailForm),
  })

  const submitUpdateUserEmail = updateEmailForm.handleSubmit(async (data) => {
    await updateUserEmail.mutateAsync({
      email: state.userInfo.email,
      newEmail: data.email,
    })
    await onClose(true)
  })

  return (
    <>
      <DialogTitle>Migrate user to new Email: {state.userInfo.individual.givenName} {state.userInfo.individual.familyName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you Sure you want to migrate this user&apos;s data to a new email?
        </DialogContentText>
        <DialogContentText>
          This is a permanent action and cannot be undone.
        </DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>
          Actions:
          <List>
            <ListItem>Projects will be migrated to the new email</ListItem>
            <ListItem>Teams will be migrated to the new email</ListItem>
            <ListItem>Chats will be migrated to the new email</ListItem>
            {state.userInfo.auth0User?.user_id.startsWith("auth0")
              ? <ListItem>This user will be updated in Auth0 with the new identity</ListItem> : <ListItem>
                This user cannot be changed in auth0 and they will need to sign in again with the new email. The old email will continue to work but will be able to onboard again.
              </ListItem>}
          </List>
        </DialogContentText>
        <TextField
          id="email"
          label="New Email Address"
          sx={{ width: "100%" }}
          error={!!updateEmailForm.formState.errors.email}
          helperText={updateEmailForm.formState.errors.email?.message}
          {...updateEmailForm.register("email")}
        />
        <TextField
          id="confirm"
          label="Confirm New Email Address"
          sx={{ width: "100%", mt:1 }}
          error={!!updateEmailForm.formState.errors.confirmEmail}
          helperText={updateEmailForm.formState.errors.confirmEmail?.message}
          {...updateEmailForm.register("confirmEmail")}

        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button variant='contained' color='warning' onClick={submitUpdateUserEmail} disabled={updateEmailForm.formState.isSubmitting} startIcon={updateEmailForm.formState.isSubmitting && <CircularProgress size="1rem" sx={{ color:"white" }} />}>Migrate</Button>
      </DialogActions>
      {updateUserEmail.isError ? <Typography color="error.main">{String(updateUserEmail.error)}</Typography> : null}
    </>
  )
}

const ResetTeamModalContent: React.FC<{state: ResetTeamState, onClose: (success: boolean) => Promise<void>}> = ({ state, onClose }) => {
  const gqlDataSrc = useCoreApiSource()
  const resetTeam = useResetTeamMutation(gqlDataSrc)

  const handleSubmitResetTeam = async () => {
    await resetTeam.mutateAsync({ teamId: state.team.id })
    await onClose(true)
  }

  return (<>
    <DialogTitle>Reset Team: {state.team.name}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you Sure you want to reset this team?
      </DialogContentText>
      <DialogContentText>
        This is a permanent action and cannot be undone.
      </DialogContentText>
      <DialogContentText sx={{ mt: 2 }}>
        Actions:
        <List>
          <ListItem>This Team will be removed.</ListItem>
          <ListItem>This Profile will be removed.</ListItem>
          <ListItem>This Team will be removed from any existing projects.</ListItem>
          <ListItem>{state.userInfo.email} will remain in any chats they are already in</ListItem>
          {state.userInfo.teams.length === 1 ? <ListItem>{state.userInfo.email} will have to onboard again on refresh.</ListItem> : null}
          {state.team.companyNumber ? <ListItem>Company {state.team.companyNumber} will be able to be claimed again during onboarding.</ListItem> : null }
        </List>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>Cancel</Button>
      <Button variant='contained' disabled={resetTeam.isLoading} startIcon={resetTeam.isLoading ? <CircularProgress size="1rem" sx={{ color:"white" }} /> : <DeleteForever />} color='error' onClick={handleSubmitResetTeam}>Reset</Button>
    </DialogActions>
    {resetTeam.isError ? <Typography color="error.main">{String(resetTeam.error)}</Typography> : null}
  </>
  )
}

export const UsersIndex: React.FC = () => {
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)
  const [ { email: search }, mergeSearch ] = useSearchState<{email: string | undefined}>({ email: undefined })
  const setSearch = (email: string|undefined) => mergeSearch({ email })

  const gqlDataSrc = useCoreApiSource()

  const userInfoQuery = useGetUserInfoQuery(gqlDataSrc, { email: search as string }, { enabled: !!search, refetchOnWindowFocus: false })

  const [ modalState, setModalState ] = useState<ModalState>()

  const { register, handleSubmit, watch } = useForm<SearchForm>({
    resolver: zodResolver(zSearchForm),
    values: {
      search,
    },
  })

  const submitSearch = handleSubmit((data) => {
    if (data.search === search) userInfoQuery.refetch()
    setSearch(data.search)
  })

  const userInfo = userInfoQuery.data?.getUserInfo

  const handleModalClose = async (success: boolean) => {
    setModalState(undefined)
    if (success) {
      await userInfoQuery.refetch()
    }
  }

  return (
    <>
      <Container sx={{ mt:2 }}>
        <Grid container rowGap={1}>
          <Grid item xs={10}>
            <TextField sx={{ width: "100%" }} id="outlined-basic" variant="outlined" autoComplete='off'
              disabled={userInfoQuery.isFetching}
              {...register("search")} onKeyDown={(e) => {
                if (e.key === "Enter") {submitSearch()} }} />
          </Grid>
          <Grid item xs={2}>
            <Button disabled={userInfoQuery.isFetching} sx={{ width: "100%", height: "100%" }} variant='contained' onClick={submitSearch}>{search === watch("search") ? <Refresh /> : "Search"}</Button>
          </Grid>
        </Grid>
        {userInfoQuery.isSuccess && !userInfo ? <Alert sx={{ mt: 2 }} color="info" icon={<Info /> }>No Results for <code>{search}</code></Alert> : null}
        {userInfoQuery.isError ? <Alert sx={{ mt: 2 }} color="error" icon={<Error /> }>{String(userInfoQuery.error)}</Alert> : null}
        {userInfo && search ? <Card sx={{ mt:2 }}>
          <CardContent>
            <Grid container columnSpacing={3}>
              <Grid item lg={4} sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: "center", width: "100%" }}>
                  <Avatar sx={{ fontSize: 80, width: 100, height: 100 }} src={userInfo.individual.pictureURL}>{getInitials(search)}</Avatar>
                  <Typography>{userInfo.individual.givenName} {userInfo.individual.familyName}</Typography>
                  <Typography>{userInfo.email}</Typography>
                  <TextField disabled value={userInfo.individual.id} label="individualId" multiline sx={{ mt:1, width: "100%" }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt:2, justifyContent: "center" }}>
                  <Button color="warning" variant="outlined" startIcon={<Email />} onClick={() => setModalState({ type: "updateUserEmail", userInfo })}>Migrate to new email</Button>
                  <Button color="info" variant="outlined" startIcon={<Edit />} onClick={() => setModalState({ type: "updateUserName", userInfo })}>Update User name</Button>
                  {userInfo.auth0User ? <Button target="_blank" href={`https://manage.auth0.com/dashboard/eu/weaver-tech/users/${encodeURIComponent(userInfo.auth0User?.user_id)}`} startIcon={<OpenInBrowser />}> View in Auth0</Button> : null }
                  <Button target="_blank" href={`https://dashboard.stripe.com/customers?email=${encodeURIComponent(search || "")}`} startIcon={<OpenInBrowser />}> Search in Stripe</Button>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                  <Box>
                    <Typography variant="h4">Teams</Typography>
                    {userInfo.teams.map(team => (
                      <Card key={team.id} sx={{ display: 'flex', p: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                              {team.name}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                              {team.type === TeamType.Contractor ? <Chip label='Contractor' icon={<Hardware />} /> : null}
                              {team.type === TeamType.Partner ? <Chip label='Partner' icon={<Handshake />} /> : null}
                              {team.type === TeamType.Architect ? <Chip label='Architect' icon={<Foundation/>} /> : null}
                              {team.type === TeamType.Homeowner ? <Chip label='Homeowner' icon={<House />} /> : null}
                              {team.type === TeamType.Weaver ? <Chip label='Weaver' icon={<SupervisorAccount />} /> : null}
                            </Typography>
                          </CardContent>
                        </Box>
                        <Box sx={{ display: "flex", gap: 2, flexDirection: "row", alignItems: "center", justifyContent: "center", whiteSpace: "nowrap" }}>
                          <Button variant='outlined' color='info' href={`${REACT_APP_APP_URL}/profile/${team.type}/${team.id}`} target='_blank'><OpenInBrowser /> View Profile</Button>
                          <Button variant='outlined' color='error' disabled={team.type === TeamType.Weaver} onClick={() => setModalState({ type: "resetTeam", userInfo, team })}><DeleteForever /> Reset</Button>
                        </Box>
                      </Card>
                    ))}
                  </Box>
                  {userInfo.projects.length ? <Box>
                    <Typography variant="h4"><Badge badgeContent={userInfo.chats.length} color="info">Chats</Badge></Typography>
                    {userInfo.chats.map(chat => (
                      <Box key={chat.id}>
                        <Typography>{chat.name}</Typography>
                      </Box>
                    ))}
                  </Box> : null}
                  {userInfo.projects.length ? <Box>
                    <Typography variant="h4"><Badge badgeContent={userInfo.projects.length} color="info">Projects</Badge></Typography>
                    {userInfo.projects.map(project => (
                      <Box key={project.id}>
                        <Link href={`${REACT_APP_APP_URL}/projects/${project.id}?ignoreScope=true`}>{project.title}</Link>
                      </Box>
                    ))}
                  </Box>: null}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card> : null}
      </Container>

      <Dialog open={modalState?.type === "updateUserName"} onClose={() => setModalState(undefined)}>{modalState?.type === "updateUserName" && <UpdateNameModalContent state={modalState} onClose={handleModalClose} />}</Dialog>
      <Dialog open={modalState?.type === "updateUserEmail"} onClose={() => setModalState(undefined)}>{modalState?.type === "updateUserEmail" && <UpdateUserEmailContent state={modalState} onClose={handleModalClose} />}</Dialog>
      <Dialog open={modalState?.type === "resetTeam"} onClose={() => setModalState(undefined)}>{modalState?.type === "resetTeam" && <ResetTeamModalContent state={modalState} onClose={handleModalClose} />}</Dialog>
    </>
  )
}
