import React, { useMemo, useRef, useState } from 'react'
import { Button, CircularProgress, Link, Modal, Typography, Alert, Snackbar } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from '@mui/x-data-grid'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useGetProjectDocumentsQuery, UploadedFileStatus, UploadedFileKind, useSetUploadedFileKindMutation, ContentDisposition, UploadedFile, useCreateUploadedFileMutation, useUpdateUploadedFileMutation, useAddProjectDocumentPreviewMutation, CreateUploadedFileMutation } from '../../graphql/generated'
import { ProjectDocument } from './types'
import { saveFileToDownloadsFolder } from '../../common/utils/files'
import { ArchiveDocumentButton } from './ArchiveDocument'
import { DateTime } from 'luxon'
import { asyncForEach } from '../../common/utils'
import { Selector } from '../../common/components/Selector/Selector'
import { objectEntries } from '../../common/utils/objects'
import { useAnalyticsEvent } from '../../common/hooks/analytics'
import { useQueryClient } from '@tanstack/react-query'
import { useWeaverFlags } from '../../api/thirdparty/launchDarkly/useWeaverFlags'

type TagArchiveDocumentModalProps = {
  openModal: boolean,
  handleCloseModal: () => void,
  projectId: string,
  projectAddress?: string,
}

const getSupportedImageFileTypes = () => (
  [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/tiff",
    "image/tif",
    "image/webp",
    "image/bmp",
    "image/heic",
  ])

const emptyBoxStyles = {
  height: 100,
  width: '80%',
  backgroundColor: 'background.default',
  borderRadius: 3,
  display: 'grid',
  placeItems: "center",
  margin: 'auto',
  marginTop: '5%',
}

export const TagArchiveDocumentModal: React.FC<TagArchiveDocumentModalProps> = (props) => {

  const inputThatOpensNativeFilePicker = useRef<HTMLInputElement>(null)
  const gqlDatasource = useCoreApiSource()
  const createUploadFileMutation = useCreateUploadedFileMutation(gqlDatasource)
  const addProjectDocumentPreviewMutation = useAddProjectDocumentPreviewMutation(gqlDatasource, {
    onSuccess: () => {
      console.debug(`[TagArchiveDocumentModal] Successfully added document preview`)
      setShowSuccessDocumentPreviewToast(true)
    },
  })
  const updateUploadFileMutation = useUpdateUploadedFileMutation(gqlDatasource)
  const queryClient = useQueryClient()

  const fireEvent_Ops_Project_Document_Tagged = useAnalyticsEvent('Ops_Project_Document_Tagged')
  const [ showAlert, setShowAlert ] = useState<boolean>(false)
  const [ showIncorrectFileTypeToast, setShowIncorrectFileTypeToast ] = useState<boolean>(false)
  const [ showSuccessDocumentPreviewToast, setShowSuccessDocumentPreviewToast ] = useState<boolean>(false)

  const { "MW-2608-ops-dashboard-upload-document-preview": opsPreviewDocUpload } = useWeaverFlags()

  const documentQueryResult = useGetProjectDocumentsQuery(gqlDatasource, {
    id: props.projectId,
    ignoreScope: true,
    config: { disposition: ContentDisposition.Inline, transformation: { width: 1000, height: 1000 } },
  },
  {
    refetchOnWindowFocus: false,
    select: (data) => {
      const { getProject } = data
      if (!getProject) throw new Error("Project not found")
      return { getProject }
    },
  })

  const updateDocumentTag = useSetUploadedFileKindMutation(gqlDatasource)

  const { openModal, handleCloseModal } = props

  const documentTypeLabels: Record<UploadedFileKind, string> = {
    [UploadedFileKind.Planning]: "Planning",
    [UploadedFileKind.Sow]: "SOW",
    [UploadedFileKind.Technical]: "Technical",
    [UploadedFileKind.Structural]: "Structural",
  }

  const handleDownloadDocument = async (document: ProjectDocument) => {
    const url = document.signedUrlForDownload.url
    const fileName = document.fileName

    if (!url) return console.error("[TagArchiveDocumentModal]: No URL for Document")
    if (!fileName) return console.error("[TagArchiveDocumentModal]: No filename for Document")

    const result = await fetch(url)
    const blob = await result.blob()
    saveFileToDownloadsFolder(fileName, blob)
  }

  const handleDownloadAll = async () => {
    if (!nonArchivedDocuments) return console.error("[TagArchiveDocumentModal]: No documents to download")

    asyncForEach(nonArchivedDocuments, async (document: ProjectDocument) => {
      await handleDownloadDocument(document)
    })
  }

  const addFileToDocumentPreviewMutation = async (file: Pick<UploadedFile, 'id' | 'signedUrlForUpload' | 'status'>) => {
    await addProjectDocumentPreviewMutation.mutateAsync({
      projectId: props.projectId,
      uploadedFileId: file.id,
    })
    console.debug(`[TagArchiveDocumentModal] Successfully added document preview @ documentId: ${file.id}`)
  }

  const handleUploadPreviewDocument = async () => {
    inputThatOpensNativeFilePicker.current?.click()
  }

  const putFileInS3 = async (id: string, url: string, file: File ) => {
    await updateUploadFileMutation.mutateAsync({ input: { id, status: UploadedFileStatus.Uploading } })

    try {
      const result = await fetch(url, { method: 'PUT', body: file })
      const status = result.status === 200 ? UploadedFileStatus.Completed : UploadedFileStatus.Failed
      await updateUploadFileMutation.mutateAsync({ input: { id, status: status } })
    } catch (e) {
      await updateUploadFileMutation.mutateAsync({ input: { id, status: UploadedFileStatus.Failed } })

      throw e
    }
  }

  const createUploadedFile = async (file: File) => {
    const createUploadResult = await createUploadFileMutation.mutateAsync({
      input: {
        fileName: file.name,
        fileContentType: file.type,
        fileSizeInBytes: file.size,
      },
    })

    if (createUploadResult){

      const { id, signedUrlForUpload } = createUploadResult.createUploadedFile

      if (!signedUrlForUpload.url) throw new Error("No signed url for upload")

      await putFileInS3(id, signedUrlForUpload.url, file)
      await addFileToDocumentPreviewMutation(createUploadResult.createUploadedFile)
      await queryClient.invalidateQueries(useGetProjectDocumentsQuery.getKey({ id: props.projectId }))
    }
  }

  const onFileSelectedByUser = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    if (target.files && target.files.length > 0) {
      const allSelectedFilesByUser = [ ...target.files ]
      const supportedFileTypes = getSupportedImageFileTypes()

      const filesWithSupportedTypes = allSelectedFilesByUser.filter(file => supportedFileTypes.includes(file.type))
      const wereThereInvalidFiles = allSelectedFilesByUser.length !== filesWithSupportedTypes.length

      if (wereThereInvalidFiles) {
        setShowIncorrectFileTypeToast(true)
      }

      Promise.all(filesWithSupportedTypes.map(async (file) => {
        await createUploadedFile(file)
      }))
    }
  }

  const handleUpdateDocumentTag = (value: string[], documentId: string) => {

    const updatedData: UploadedFileKind[]  = objectEntries(documentTypeLabels).filter(([ , documentLabel ]) =>
      value.includes(documentLabel)).map(([ key ]) => key)

    updateDocumentTag.mutateAsync({
      uploadedFileKind: updatedData,
      uploadedFileId: documentId,
    }, {
      onSuccess: () => {
        console.debug(`[TagArchiveDocumentModal] Successfully tagged document with Kind @ documentId: ${documentId}`)
        fireEvent_Ops_Project_Document_Tagged({
          projectId: props.projectId,
          documentId: documentId,
          type: updatedData,
        })
      },
      onError: () => {
        console.debug(`[TagArchiveDocumentModal] Failed to tag document with Kind @ documentId: ${documentId}`)
        setShowAlert(true)
      },
    })
  }

  const columns = useMemo<GridColDef<ProjectDocument>[]>(() => [
    { sortable: false, flex: 1, maxWidth: 500, field: 'name', headerName: 'Document name', headerAlign: 'center', renderCell: (params) => <Link sx={{ cursor: 'pointer' }} target='_blank' href={params.row.signedUrlForDownload?.url ?? undefined} ><Typography variant="h6">{`${props.projectAddress} - ${params.row.fileName}`}</Typography></Link> },
    { sortable: false, flex: 1, maxWidth: 200, minWidth: 200, field: 'date', align: 'center', headerName: 'Upload date', headerAlign: 'center', renderCell: (params) => <Typography variant="body1">{DateTime.fromISO(params.row.updatedAt).toFormat('dd/MM/yyyy')}</Typography> },
    { sortable: false, flex: 1, maxWidth: 350, minWidth: 350, field: 'type', headerName: 'Type', headerAlign: 'center', renderCell: (params) => <Selector inputOptions={documentTypeLabels} hasFilter={true} isMultipleSelect={true} rowId={params.row.id} initialData={params.row.kind ?? []} onSelectUpdate={handleUpdateDocumentTag} /> },
    { sortable: false, flex: 1, maxWidth: 100, minWidth: 100, field: 'archive', align: 'center', headerName: 'Archive', headerAlign: 'center', renderCell: (params) => <ArchiveDocumentButton documentId={params.row.id} projectId={props.projectId} type={params.row.kind ?? []} /> },
  ], [])

  const previewDocumentColumns = useMemo<GridColDef<Partial<UploadedFile>>[]>(() => [
    { sortable: false, flex: 1, maxWidth: 500, field: 'name', headerName: 'Tender teaser name', headerAlign: 'center', renderCell: (params) => <Link sx={{ cursor: 'pointer' }} target='_blank' href={params.row.signedUrlForDownload?.url ?? undefined} ><Typography variant="h6">{`${props.projectAddress} - ${params.row.fileName}`}</Typography></Link> },
    { sortable: false, flex: 1, maxWidth: 200, minWidth: 200, field: 'date', align: 'center', headerName: 'Upload date', headerAlign: 'center', renderCell: (params) => params.row?.createdAt && <Typography variant="body1">{DateTime.fromISO(params.row?.createdAt).toFormat('dd/MM/yyyy')}</Typography> },
    { sortable: false, flex: 1, maxWidth: 100, minWidth: 100, field: 'archive', align: 'center', headerName: 'Archive', headerAlign: 'center', renderCell: (params) => <ArchiveDocumentButton documentId={params.row.id ?? ''} projectId={props.projectId} type={params.row.kind ?? []} /> },
  ], [])

  const { data, error, isLoading, isFetching } = documentQueryResult

  const documentData = data?.getProject?.documents
  const documentPreviewData = data?.getProject?.documentPreviews

  const includeNonArchivedDocuments = (document: ProjectDocument) => document?.status !== UploadedFileStatus.Archived
  const includeNonArchivedPreviewDocuments = (document: Partial<UploadedFile>) => document?.status !== UploadedFileStatus.Archived
  const nonArchivedDocuments = documentData?.filter(includeNonArchivedDocuments)
  const nonArchivedPreviewDocuments = documentPreviewData?.filter(includeNonArchivedPreviewDocuments)

  if (error) {
    console.error("[TagArchiveDownloadModal]:", error)
  }

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        height: "90%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
      }}>
        {isFetching || isLoading
          ? <CircularProgress />
          : error
            ? <Typography textAlign='center' variant="h6">An error occurred please refresh or report to engineering if the problem persists</Typography>
            : <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button sx={{ marginBottom: 2 }} onClick={handleCloseModal} variant="outlined">Close</Button>
              </Box>
              {opsPreviewDocUpload &&
              <Box sx={{ display: 'flex' }}>
                <input hidden ref={inputThatOpensNativeFilePicker} type="file" id="nativeFilePicker" onChange={onFileSelectedByUser} multiple={true}/>
                <Button sx={{ marginBottom: 2 }} onClick={handleUploadPreviewDocument} variant="contained">Upload tender teaser images</Button>
                <Link
                  sx={{ marginLeft: 5, alignSelf: 'center',  marginBottom: 2 }} target='_blank' href={`https://docs.google.com/presentation/d/16tG9DgTUMQtoe7C15YMg9UgbkeE5pUAWqZ0aJ10Vo9Q/edit#slide=id.g2529b0f81cc_1_250`} variant="body1">
                  Instructions
                </Link>
              </Box>
              }

              {!nonArchivedPreviewDocuments?.length
                ? opsPreviewDocUpload
                  ? <Box sx={emptyBoxStyles}>
                    <Typography textAlign='center' variant="h6">No non-archived preview documents found</Typography>
                  </Box>
                  : null
                : <Box sx={{ width: '100%', overflow: 'scroll' }}>
                  <DataGrid
                    autoHeight
                    rowHeight={80}
                    rows={nonArchivedPreviewDocuments}
                    columns={previewDocumentColumns as any}
                    disableSelectionOnClick={true}
                    disableColumnMenu={true}
                    onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
                      // This fixes an error being thrown on click of our select disabled rows
                      event.stopPropagation()
                    }}
                  />
                </Box>}

              {!!nonArchivedDocuments?.length  &&
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                <Button sx={{ marginBottom: 2 }} onClick={handleDownloadAll} variant="contained">Download all</Button>
              </Box>
              }

              {!nonArchivedDocuments?.length
                ? <Box sx={emptyBoxStyles}>
                  <Typography textAlign='center' variant="h6">No non-archived documents found</Typography>
                </Box>
                : <Box sx={{ width: '100%', overflow: 'scroll' }}>
                  <DataGrid
                    autoHeight
                    rowHeight={80}
                    rows={nonArchivedDocuments}
                    columns={columns}
                    disableSelectionOnClick={true}
                    disableColumnMenu={true}
                    onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
                      // This fixes an error being thrown on click of our select disabled rows
                      event.stopPropagation()
                    }}
                  />
                </Box>}

            </>}
        {showAlert && <Alert severity="error">Sorry adding the document tag has failed - Please refresh the browser</Alert>}
        <Snackbar open={showIncorrectFileTypeToast} autoHideDuration={6000} onClose={() => setShowIncorrectFileTypeToast(false)}>
          <Alert onClose={() => setShowIncorrectFileTypeToast(false)} severity="warning" sx={{ width: '100%' }}>
            One or more of the files were not a valid image file and have not been uploaded
          </Alert>
        </Snackbar>

        <Snackbar open={showSuccessDocumentPreviewToast} autoHideDuration={6000} onClose={() => setShowSuccessDocumentPreviewToast(false)}>
          <Alert onClose={() => setShowSuccessDocumentPreviewToast(false)} severity="success" sx={{ width: '100%' }}>
            Project document preview successfully uploaded
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  )
}
