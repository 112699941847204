import React, { useMemo } from 'react'
import { ProjectMemberRole, TeamType, useAddProjectSlotMutation, useRemoveProjectInviteMutation } from '../../../graphql/generated'

import { getInitials } from '../../../common/utils/text'
import Tooltip from '@mui/material/Tooltip'
import Styles from "./CommonMemberDisplays.module.scss"
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import { Project } from "../types"
import { sortByCreated, sorted } from "../../../common/utils/sort"
import CandidateIcon from '@mui/icons-material/PersonOutline'
import InviteIcon from '@mui/icons-material/EmailOutlined'
import AddSlotIcon from '@mui/icons-material/AddCircle'
import AddInviteIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import { Button, Typography } from '@mui/material'
import { ClearAll } from '@mui/icons-material'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useMutation } from '@tanstack/react-query'

type BuilderDisplayProps = {
  project: Project,
}
const BuilderDisplay: React.FC<BuilderDisplayProps> = ({ project }) => {
  const gqlDatasource = useCoreApiSource()
  const { handleManageAddProjectInvite, handleManageInvite, handleManageMember, handleManageSlot, refreshRow } = useRequiredContext(ProjectsIndexContext)

  const slots = useMemo(() => project.memberSlots.sort(sortByCreated), [ project.memberSlots ])
  const candidates = useMemo(() => sorted(project.members.filter(x => x.projectRole === ProjectMemberRole.CandidateProfessional), sortByCreated), [ project.members ])

  const memberInvites = useMemo(() => project.memberInvites.sort(sortByCreated), [ project.memberInvites ])
  const removeInvite = useRemoveProjectInviteMutation(gqlDatasource)
  const addSlot = useAddProjectSlotMutation(gqlDatasource)

  const handleClearInvites = useMutation(async () => {
    if (removeInvite.isLoading) return
    const inviteIds = project.memberInvites.map(x => x.id)
    await Promise.allSettled(inviteIds.map(inviteId => removeInvite.mutateAsync({ id: inviteId })))
    await refreshRow.mutateAsync(project.id)
  })

  const handleAddSlot = useMutation(async () => {
    await addSlot.mutateAsync({ input: { projectId: project.id, requiredProjectRole: ProjectMemberRole.CandidateProfessional, requiredTeamType: TeamType.Contractor } })
    await refreshRow.mutateAsync(project.id)
  })

  return (
    <div className={Styles.container}>
      <div className={Styles.chipRow}>
        <div className={Styles.rowIcon}><InviteIcon /></div>
        {memberInvites.map(x => {
          if (x.team) {
            return <Tooltip sx={{ bgcolor: 'warning.main', color: "white", border: 0 }} className={Styles.item} key={x.id} title={`Invite: ${x.team.name || "Unknown Name"}`} onClick={() => handleManageInvite(project, x)}>
              <Typography>{getInitials(x.team.name || "X")} </Typography>
            </Tooltip>
          }
          return <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} key={x.id} title={`Invite: ${x.companyName || "Unknown Name"}`} onClick={() => handleManageInvite(project, x)}>
            <Typography>{x.companyName ? getInitials(x.companyName) : "?"}</Typography>
          </Tooltip>
        })}
        <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} title="Invite Contractor" onClick={() => handleManageAddProjectInvite(project)}>
          <Typography><AddInviteIcon /></Typography>
        </Tooltip>
        <Button disabled={handleClearInvites.isLoading} color='error' sx={{ minWidth:0, p: 0.2 }} title='Clear all Invites' variant='outlined' onClick={() => handleClearInvites.mutate()}><ClearAll /></Button>
      </div>
      <div className={Styles.chipRow}>
        <div className={Styles.rowIcon}><CandidateIcon /></div>
        {candidates.map(x => {
          return <Tooltip sx={{ bgcolor: 'info.main', color: "white", border: 0 }} className={Styles.item} key={x.team.id} title={`${x.projectRole}: ${x.team.name || "Unknown"}`} onClick={() => handleManageMember(project, x)}>
            <Typography color="text.secondary">{getInitials(x.team.name || "X")} </Typography>
          </Tooltip>
        })}
        {slots.map(x => <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} key={x.id} title={`Slot: ${x.requiredTeamType} ${x.requiredProjectRole}`} onClick={() => handleManageSlot(project, x)} >
          <Typography color="text.info">*</Typography>
        </Tooltip>)}
        <Tooltip title="Add Slot"><Button disabled={handleAddSlot.isLoading} color='success' sx={{ minWidth:0, p: 0.2 }} variant='outlined' onClick={() => handleAddSlot.mutateAsync()}><AddSlotIcon /></Button></Tooltip>
      </div>

    </div>
  )
}

export default BuilderDisplay
